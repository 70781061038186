import * as React from 'react';
import { IoIosCall } from 'react-icons/io';

const ContainersCTA = () => (
  <article className="w-full max-w-5xl mx-auto my-auto">
    <p className="text-6xl font-semibold leading-none uppercase font-display text-brand-blue">
      Versatile <br />
      shipping containers <br />
      available for <br />
      <span className="text-brand-orange">purchase</span>
    </p>
    <div className="flex-shrink-0 w-full mb-2 sm:w-auto">
      <a
        href={`tel:1300762038`}
        className="flex items-center justify-center w-full mt-4 bg-white shadow border-brand-orange text-brand-orange sm:w-56 hover:shadow-md button"
      >
        <IoIosCall />
        <span className="ml-2">Call: 1300 762 038</span>
      </a>
    </div>
  </article>
);

export default ContainersCTA;
