import * as React from 'react';

import Container from './container';
import FortyFoot from './forty-foot';
import TenFoot from './ten-foot';
import TwentyFoot from './twenty-foot';

const ContainerSizes = () => (
  <div
    id="container-sizes"
    className="flex flex-wrap w-full px-6 py-4 md:-mx-2"
  >
    <Container size="10 foot">
      <TenFoot />
    </Container>
    <Container size="20 foot">
      <TwentyFoot />
    </Container>
    <Container size="40 foot">
      <FortyFoot />
    </Container>
  </div>
);

export default ContainerSizes;
