/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';

const FortyFoot = () => (
  <table className="relative w-full rich-text">
    <thead className="sticky top-0 shadow-md bg-brand-blue">
      <tr>
        <th />
        <th className="text-right">40ft GP</th>
        <th className="text-right">40ft HC</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th className="text-left">Outside Length</th>
        <td className="text-right">12.192m</td>
        <td className="text-right">12.192m</td>
      </tr>
      <tr>
        <th className="text-left">Outside Height</th>
        <td className="text-right">2.591m</td>
        <td className="text-right">2.896m</td>
      </tr>
      <tr>
        <th className="text-left">Outside Width</th>
        <td className="text-right">2.438m</td>
        <td className="text-right">2.438m</td>
      </tr>
      <tr />
      <tr>
        <th className="text-left">Inside Length</th>
        <td className="text-right">12.032m</td>
        <td className="text-right">12.000m</td>
      </tr>
      <tr>
        <th className="text-left">Inside Height</th>
        <td className="text-right">2.390m</td>
        <td className="text-right">2.695m</td>
      </tr>
      <tr>
        <th className="text-left">Inside Width</th>
        <td className="text-right">2.345m</td>
        <td className="text-right">2.345m</td>
      </tr>
      <tr />
      <tr>
        <th className="text-left">Max Gross Weight</th>
        <td className="text-right">30,400kg</td>
        <td className="text-right">30,848kg</td>
      </tr>
      <tr>
        <th className="text-left">Net Load</th>
        <td className="text-right">26,600kg</td>
        <td className="text-right">28,580kg</td>
      </tr>
      <tr>
        <th className="text-left">Empty Weight (Tare)</th>
        <td className="text-right">3,800kg</td>
        <td className="text-right">3,900kg</td>
      </tr>
      <tr>
        <th className="text-left">Door Height</th>
        <td className="text-right">2.280m</td>
        <td className="text-right">2.560m</td>
      </tr>
      <tr>
        <th className="text-left">Door Width</th>
        <td className="text-right">2.340m</td>
        <td className="text-right">2.340m</td>
      </tr>
      <tr />
      <tr>
        <th className="text-left">Volume</th>
        <td className="text-right">
          67.50m<sup>3</sup>
        </td>
        <td className="text-right">
          75.30m<sup>3</sup>
        </td>
      </tr>
    </tbody>
  </table>
);

export default FortyFoot;
