import * as React from 'react';

import { Hero, Layout, SEO } from '../components';
import ContainerSizes from '../components/containers/container-sizes';
import CTA from '../components/containers/containers-cta';
import FirstBanner from '../components/containers/containers-first-banner';
import FirstSection from '../components/containers/containers-first-section';
import HeroImage from '../components/containers/containers-hero-image';

const ContainersPage = () => (
  <Layout
    formName="containers-page-bottom-form"
    formTitle="Buying or hiring? Contact us today"
  >
    <SEO
      title="Shipping Containers Port Macquarie, NSW | Frontline Removals"
      description="Versatile Shipping Containers Port Macquarie, NSW. Available for Purchase & for Hire (for the use of storage and shipping). Buying or Hiring? Contact us today."
    />
    <Hero image={<HeroImage />} cta={<CTA />} />
    <FirstSection />
    <FirstBanner />
    <ContainerSizes />
  </Layout>
);

export default ContainersPage;
