import { Link } from 'gatsby';
import * as React from 'react';

const ContainersFirstBanner = () => (
  <section
    id="containers-banner"
    className="flex items-center justify-center px-6 py-6 mt-12 text-center bg-brand-blue"
  >
    <h2 className="text-4xl font-semibold leading-tight text-white uppercase font-display">
      <Link
        to="/contact/"
        className="inline-block px-2 rounded bg-brand-orange"
      >
        Buy a shipping container
      </Link>{' '}
      <span className="inline-block">The possibilities are endless</span>
    </h2>
  </section>
);

export default ContainersFirstBanner;
