import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import { animated, config, useSpring } from 'react-spring';

const Container = ({ size, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const iconProps = useSpring({
    transform: `rotate(${isOpen ? 0 : -45}deg)`,
    config: config.stiff,
  });

  return (
    <div className="w-full px-2 mt-6 lg:mt-0 lg:w-1/3 first:mt-0">
      <div className="w-full overflow-hidden text-center text-white rounded-lg bg-brand-blue">
        <h3 className="relative w-full p-3">
          <button
            onClick={() => setIsOpen(!isOpen)}
            type="button"
            className="w-full py-1 text-4xl leading-none uppercase rounded bg-brand-orange font-display"
          >
            {size}
            <div className="absolute inset-y-0 right-0 flex items-center mr-3 text-3xl">
              <animated.span style={iconProps}>
                <IoIosClose />
              </animated.span>
            </div>
          </button>
        </h3>
      </div>
      <div
        className={`${
          isOpen ? 'bg-brand-blue text-white px-6 py-6 rounded-lg' : 'hidden'
        } -mt-3`}
      >
        {children}
      </div>
    </div>
  );
};

Container.propTypes = {
  size: PropTypes.string,
  children: PropTypes.node,
};

export default Container;
