import { Link } from 'gatsby';
import * as React from 'react';

import LiCheck from '../utils/li-check';

const ContainersFirstSection = () => (
  <article className="flex flex-wrap max-w-6xl px-6 py-4 mx-auto mt-20">
    <div className="w-full md:w-1/3">
      <h1 className="text-5xl font-semibold leading-none uppercase heading font-display">
        Shipping
        <br />
        Containers <br />
        Port Macquarie
      </h1>
    </div>
    <div className="w-full mt-6 md:mt-0 md:w-2/3">
      <div className="rich-text">
        <h2>Our niche: the packing and selling of shipping containers</h2>
        <p>
          Here at{' '}
          <Link to="https://www.frontlineremovals.com.au/">
            Frontline Removals
          </Link>
          , shipping containers are our speciality. Not only do we use shipping
          containers for effortless relocation and storage — we also sell them!
        </p>
        <p>
          In the {new Date().getFullYear() - 1995} odd years we’ve been in
          business, the world has changed around us — shipping containers have
          become a storage and renovation trend, so they’re now optimised for
          all sorts of purposes.
        </p>
        <h3>The possibilities are endless:</h3>
        <ul>
          <LiCheck>
            <strong>Personal storage:</strong> extra space for tools, furniture,
            farming tools and more.
          </LiCheck>
          <LiCheck>
            <strong>Shipping container homes:</strong> convert into a living
            space or sleep-out.
          </LiCheck>
          <LiCheck>
            <strong>Outdoor utilities:</strong> convert into a tool-shed,
            outdoor bar — whatever you fancy!
          </LiCheck>
        </ul>
        <h2>Looking to buy a shipping container? Heres your options:</h2>
        <h3>A range of sizes, ready to customise:</h3>
        <p>
          Our shipping containers for sale are well-rounded. From low to high
          cubes — we offer containers of a variety of sizes.
        </p>
        <ul>
          <LiCheck>
            <strong>10ft container:</strong> ideal for regular sized items such
            as sports equipment or tools.
          </LiCheck>
          <LiCheck>
            <strong>20ft container:</strong> big enough to store a vehicle, this
            option is suitable for large-scale items.
          </LiCheck>
          <LiCheck>
            <strong>40ft container:</strong> the perfect size to convert to a
            farm shed or living space.
          </LiCheck>
        </ul>
        <h3>High-quality steel containers, new or old:</h3>
        <p>
          Made from solid steel, our shipping containers are structured to
          secure your belongings or to adapt for renovation. Choose from a brand
          new container, or opt for a second-hand one with a story.
        </p>
        <h2>Shipping containers that are strong and secure</h2>
        <p>
          Whether you’re looking to create a shipping container home, or you’re
          simply searching for extra storage for your tools and other bits and
          pieces — our containers are safe, and versatile to your needs. And
          that’s why we offer them for purchase!
        </p>
        <h3>The perks of owning a shipping container:</h3>
        <ul>
          <LiCheck>Easily portable</LiCheck>
          <LiCheck>Adaptable</LiCheck>
          <LiCheck>Safe, secure</LiCheck>
          <LiCheck>Affordable</LiCheck>
        </ul>
      </div>
    </div>
  </article>
);

export default ContainersFirstSection;
